





















































































import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { elementOrNull } from "@/helpers/classHelper";

@Component({
    components: {
        BaseWrapper,
    },
})
export default class WebMainHero extends Vue {
    @Prop() loading!: boolean;
    @Prop() elements!: any[];

    elementOrNull(elementName: string) {
        return elementOrNull(this.elements, elementName);
    }
}
