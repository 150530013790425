


























import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import WebMainCompanies from "@/components/Web/Main/Companies.vue";
import WebMainFeatures from "@/components/Web/Main/Features.vue";
import WebMainFooter from "@/components/Web/Main/Footer.vue";
import WebMainHero from "@/components/Web/Main/Hero.vue";
import WebMainRecentActivities from "@/components/Web/Main/RecentActivities.vue";
import WebMainStatistics from "@/components/Web/Main/Statistics.vue";
import NavigationBar from "@/components/Web/Others/NavigationBar.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        NavigationBar,
        BaseWrapper,
        WebMainHero,
        WebMainStatistics,
        WebMainFeatures,
        WebMainRecentActivities,
        WebMainCompanies,
        WebMainFooter,
    },
})
export default class WebMain extends Vue {
    public isLoading: boolean = false;

    get elements() {
        return this.$store.state.elements.elements;
    }

    get sponsors() {
        return this.$store.state.sponsors.sponsors;
    }

    get convertedElements() {
        let convertedElements: any = {};

        this.elements.map((element: any) => {
            convertedElements[element.name] = element;
        });

        return convertedElements;
    }

    async getElements() {
        this.isLoading = true;
        await this.$store.dispatch("elements/getElements");

        setTimeout(() => {
            this.isLoading = false;
        }, 100);
    }

    async getSponsors() {
        this.isLoading = true;
        await this.$store.dispatch("sponsors/getSponsors");

        setTimeout(() => {
            this.isLoading = false;
        }, 100);
    }

    async mounted() {
        await this.getElements();
        await this.getSponsors();
    }
}
