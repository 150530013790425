


















import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseWrapper,
    },
})
export default class WebMainFooter extends Vue {
    public backgroundColor: string = "grey darken-4";
}
