export const elementOrNull = (elements: any[], elementName: any) => {
    let text: string = "";

    const hasValue =
        elements &&
        elements[elementName] &&
        elements[elementName].value;

    if (hasValue) text = elements[elementName].value;

    return text;
}