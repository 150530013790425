var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-wrapper',{attrs:{"variant":"fullscreen","color":"#FAFAFA","minHeight":"1%"}},[_c('base-wrapper',{attrs:{"variant":"large","color":"transparent","minHeight":"1%"}},[_c('v-sheet',{staticClass:"py-12",attrs:{"color":"transparent"}},[_c('v-layout',{staticClass:"align-center",attrs:{"column":""}},[_c('v-layout',{staticClass:"align-center features-gradient",attrs:{"column":""}},[_c('app-icon',{staticClass:"mb-4 ml-4",attrs:{"color":"primary","size":"56","imageSize":"28"}}),_c('h1',{staticClass:"\n                                mb-2\n                                text-h5\n                                font-weight-bold\n                                text-center text-uppercase\n                            ",class:_vm.loading
                                    ? 'featureTitle skeleton-animation'
                                    : '',domProps:{"textContent":_vm._s(
                                _vm.loading ? '' : _vm.elementOrNull('featureTitle')
                            )}}),_c('others-simple-line')],1),_c('v-row',{staticClass:"mt-8",attrs:{"no-gutters":""}},_vm._l((_vm.features),function(feature,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"4"}},[_c('v-layout',{staticClass:"align-center pa-4 px-lg-2",attrs:{"column":""}},[_c('v-icon',{staticClass:"mb-6",class:_vm.loading
                                            ? 'featureItemIcon skeleton-animation'
                                            : '',attrs:{"size":"56","color":"primary"},domProps:{"textContent":_vm._s(
                                        _vm.loading
                                            ? ''
                                            : _vm.elementOrNull(
                                                  _vm.indexToElement[index] +
                                                      'Icon'
                                              )
                                    )}}),_c('h3',{staticClass:"\n                                        mb-1\n                                        text-h6\n                                        font-weight-bold\n                                        text-center\n                                    ",class:_vm.loading
                                            ? 'featureItemTitle skeleton-animation'
                                            : '',domProps:{"textContent":_vm._s(
                                        _vm.loading
                                            ? ''
                                            : _vm.elementOrNull(
                                                  _vm.indexToElement[index] +
                                                      'Title'
                                              )
                                    )}}),_c('others-simple-line'),_c('p',{staticClass:"\n                                        text-body-1\n                                        grey--text\n                                        text--darken-1 text-center\n                                        font-weight-light\n                                        mt-6\n                                    ",class:_vm.loading
                                            ? 'featureItemDescription skeleton-animation'
                                            : '',domProps:{"textContent":_vm._s(
                                        _vm.loading
                                            ? ''
                                            : _vm.elementOrNull(
                                                  _vm.indexToElement[index] +
                                                      'Description'
                                              )
                                    )}})],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }