












































import { Component, Vue } from "vue-property-decorator";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import OthersSimpleLine from "@/components/Others/SimpleLine.vue";
import { toCurrency } from "@/helpers/Formatter";

@Component({
    components: {
        BaseWrapper,
        OthersSimpleLine,
    },
})
export default class WebMainStatistics extends Vue {
    get landingStatistics() {
        return this.$store.state.statistics.landingStatistics;
    }

    get statistics() {
        let statistics: any[] = [
            {
                icon: "mdi-account-multiple",
                subtitle: this.landingStatistics.proposersCount + " pengusul",
                title: "Pengusul",
            },
            {
                title: "Proposal",
                subtitle: this.landingStatistics.proposalsCount + " proposal",
                icon: "mdi-clipboard-multiple",
            },
            {
                title: "Perusahaan",
                subtitle: this.landingStatistics.companiesCount + " perusahaan",
                icon: "mdi-domain",
            },
            {
                title: "Dana CSR Diberikan",
                subtitle: toCurrency(this.landingStatistics.fundsTotal),
                icon: "mdi-cash-multiple",
            },
        ];

        return statistics;
    }

    async getLandingStatistic() {
        await this.$store.dispatch("statistics/getLandingStatistics");
    }

    mounted() {
        this.getLandingStatistic();
    }
}
