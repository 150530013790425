
























































import BaseLink from "@/components/Base/BaseLink.vue";
import AppIcon from "@/components/Others/AppIcon.vue";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
    components: {
        AppIcon,
        BaseLink,
    },
})
export default class WebOthersNavigationBar extends Vue {
    public showBottomSheet: boolean = false;

    get navigations() {
        let navigations: any[] = [
            { text: "Utama", type: "hash", link: "main" },
            { text: "Fitur", type: "hash", link: "features" },
            { text: "Perusahaan", type: "hash", link: "companies" },
            { text: "Aktivitas", type: "hash", link: "activities" },
            { text: "Panduan", type: "url", link: "/panduan" },
            { text: "Masuk", type: "url", link: "/masuk" },
        ];

        if (this.currentRoute !== "/") {
            navigations = [
                { text: "Utama", type: "url", link: "/" },
                { text: "Masuk", type: "url", link: "/masuk" },
            ];
        }

        return navigations;
    }

    hashOrLink(navigation: any) {
        let link = navigation.link;
        const isHash = navigation.type === "hash";

        if (isHash) link = "#" + navigation.link;
        // if (isHash) link = this.$vuetify.goTo("#" + navigation.link);

        return link;
    }

    get currentRoute() {
        return this.$route.path;
    }

    @Watch("$route.hash")
    scrollTo(hash: string) {
        this.$vuetify.goTo(hash);
    }
}
