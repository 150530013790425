





















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import AppIcon from "@/components/Others/AppIcon.vue";
import OthersSimpleLine from "@/components/Others/SimpleLine.vue";
import { elementOrNull } from "@/helpers/classHelper";

@Component({
    components: {
        BaseWrapper,
        AppIcon,
        OthersSimpleLine,
    },
})
export default class WebMainFeatures extends Vue {
    @Prop() loading!: boolean;
    @Prop() elements!: any[];

    elementOrNull(elementName: string) {
        return elementOrNull(this.elements, elementName);
    }

    get indexToElement() {
        return ["featureFirstItem", "featureSecondItem", "featureThirdItem"];
    }

    public appLogo: any = require("@/assets/logo.png");

    public features: any[] = [
        {
            icon: "mdi-domain",
            title: "CSR Tercatat Secara Digital",
            subtitle:
                "Memudahkan perusahaan dan Dinas Sosial untuk memantau kegiatan CSR.",
        },
        {
            icon: "mdi-clipboard",
            title: "Proposal Dilihat Banyak Perusahaan",
            subtitle:
                "Proposal yang dibuat oleh pengusul dapat dilihat oleh semua perusahaan yang terdaftar.",
        },
        {
            icon: "mdi-check",
            title: "Terintegrasi",
            subtitle: "Semua data telah terintegrasi dengan data dari.",
        },
    ];
}
