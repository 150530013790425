






















































































import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import OthersSimpleLine from "@/components/Others/SimpleLine.vue";
import { Component, Vue } from "vue-property-decorator";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import StatisticsService from "@/services/StatisticsService";
import { loadFile } from "@/helpers/useLoader";
import vuexErrorHandler from "@/helpers/vuexErrorHandler";
const { Splide, SplideSlide } = require("@splidejs/vue-splide");

@Component({
    components: {
        Splide,
        SplideSlide,
        BaseWrapper,
        OthersSimpleLine,
    },
})
export default class WebMainRecentActivities extends Vue {
    public isLoaded: boolean = false;

    public options: any = {
        padding: {
            left: 0,
            right: "6rem",
        },
        breakpoints: {
            600: {
                perPage: 1,
            },
        },
        cover: true,
        rewind: true,
        perPage: 2,
        autoplay: true,
        pauseOnHover: false,
        arrows: "slider",
    };

    public topGalleries: any[] = [];

    get images(): any[] {
        let images: any[] = [];
        const items: string[] = [
            "image_0_path",
            "image_1_path",
            "image_2_path",
        ];

        this.topGalleries.map((gallery: any) => {
            const isImage0Exist: boolean = !!gallery.image_0_path;
            const isImage1Exist: boolean = !!gallery.image_1_path;
            const isImage2Exist: boolean = !!gallery.image_2_path;

            if (isImage0Exist)
                images.push({
                    companyName: gallery.user.profile.display_name,
                    path: gallery.image_0_path,
                });
            if (isImage1Exist)
                images.push({
                    companyName: gallery.user.profile.display_name,
                    path: gallery.image_1_path,
                });
            if (isImage2Exist)
                images.push({
                    companyName: gallery.user.profile.display_name,
                    path: gallery.image_2_path,
                });
        });

        return images;
    }

    async getTopGalleries() {
        const year = new Date().getFullYear();

        try {
            const response = await StatisticsService.getTopGalleries(year);
            // console.log(response);

            const { galleries } = response.data;

            this.topGalleries = galleries;
            // console.log(response);
        } catch (err) {
            vuexErrorHandler(err);
        }
    }

    loadFile(path: string) {
        return loadFile(path);
    }

    async mounted() {
        await this.getTopGalleries();
        setTimeout(() => {
            this.isLoaded = true;
        }, 100);
    }
}
